<template>
  <!--
    Used to manage the fonts that are used in videoPostprocessing.
  -->
  <div class="fontsList">
    <LoadingPlaceholder v-if="loading" />
    
    <template v-else>
      <Grid
        :ref="kgm_ref"
        :style="{height: 'auto'}"
        :data-items="kgm_computedGridItems(fonts)"
        :columns="kgm_responsiveColumns()"
        :filterable="true"
        :filter="kgm_filter"
        :pageable="kgm_pagable"
        :page-size="kgm_take"
        :skip="kgm_skip"
        :take="kgm_take"
        :total="kgm_allGridItems(fonts)"
        :sortable="{
          allowUnsort: kgm_allowUnsort,
          mode: kgm_sortMode
        }"
        :sort="kgm_sort"
        selected-field="selected"
        @rowclick="kgm_gridOnRowClick"
        @filterchange="kgm_gridFilterChange"
        @pagechange="kgm_gridPageChange"
        @sortchange="kgm_gridSortChange"
      >
        <div
          slot="nameFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="nameFilterInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a name"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('nameFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <div
          slot="descriptionFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="descriptionFilterInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a description"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('descriptionFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <template
          slot="optionsTemplate"
          slot-scope="{props}"
        >
          <td :class="props.className">
            <div class="btn-group">
              <button
                class="btn btn-primary btn-sm"
                @click="showSidebarEdit(props.dataItem)"
              >
                <font-awesome-icon
                  class="mr-2"
                  icon="edit"
                />
                <span>Edit</span>
              </button>
              <button
                slot="button"
                class="btn btn-secondary btn-sm"
                @click="testFont(props.dataItem.id)"
              >
                <font-awesome-icon
                  class="mr-2 gray"
                  icon="file-alt"
                />Test
              </button>
              <button
                class="btn btn-secondary btn-sm"
                @click="openConfirmModal(props.dataItem.id)"
              >
                <font-awesome-icon
                  class="mr-2 gray"
                  icon="trash"
                />
                <span>Remove</span>
              </button>
            </div>
          </td>
        </template>
      </Grid>
      <Sidebar
        v-if="showSidebar"
        :show-sidebar="showSidebar"
        @close="close"
      >
        <FontsAdd
          v-if="addingElement"
          @reload="reloadFonts"
        />
        <FontsEdit
          v-else
          :font="font"
          @reload="reloadFonts"
        />
      </Sidebar>
    </template>

    <SweetModal
      ref="confirmDelete"
      :title="$t('introOutroListComp.deleteElement')"
      icon="warning"
      blocking
      class="overflowHidden"
    >
      <p>{{ $t('introOutroListComp.sureToDelete') }}</p>
      <button
        slot="button"
        class="btn btn-secondary float-left mb-3"
        @click="$refs.confirmDelete.close()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="times"
        />{{ $t('cancel') }}
      </button>
      <button
        slot="button"
        class="btn btn-danger float-right mb-3"
        @click="removeFont()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="trash"
        />{{ $t('delete') }}
      </button>
      <div class="clearfix" />
    </SweetModal>
    <SweetModal
      ref="imageViewerModal"
      title="<span>Test Image</span>"
    >
      <img
        v-if="testImageUrl"
        loading="lazy"
        :src="'data:image/png;base64,' + testImageUrl"
        class="img-fluid"
      >
    </SweetModal>
  </div>
</template>

<script>

import { SweetModal } from 'sweet-modal-vue';
import { kendoGridMixin } from '@/mixins/kendoGridMixin.js';
import Sidebar from '@/components/Base/Sidebar.vue';
import FontsEdit from '@/components/VideoPostprocessing/Fonts/FontsEdit.vue';
import FontsAdd from '@/components/VideoPostprocessing/Fonts/FontsAdd.vue';

export default {
  name: "FontsList",
  components: {
    SweetModal,
    Sidebar,
    FontsEdit,
    FontsAdd
  },
  mixins: [
    kendoGridMixin
  ],
  data () {
    return {
      loading: true,
      fonts: null,
      showSidebar: false,
      addingElement: false,
      currentId: null,
      deleteIndex: null,
      testImageUrl: null,
      kgm_filter: {
        logic: "and",
        filters: [
          {
            field: 'name',
            operator: 'contains',
            value: ''
          }
        ]
      },
      kgm_sort: [
        {
          "field": "name",
          "dir": "asc"
        }
      ],
      kgm_columns: [
        {
          field: 'name',
          filterable: true,
          filter: 'text',
          title: 'Name',
          filterCell: "nameFilter"
        },
        {
          field: 'description',
          filterable: true,
          filter: 'text',
          title: 'Description',
          filterCell: "descriptionFilter"
        },
        {
          filterable: false,
          title: 'Options',
          cell: 'optionsTemplate',
          width: '300px'
        }
      ]
    }
  },
  metaInfo () {
    return {
      title: this.$t('menu.fonts')
    }
  },
  created () {
    this.loadingFonts();
  },
  methods: {
    //#region Sidebar
    show () {
      this.showSidebar = true;
    },
    close () {
      this.showSidebar = false;
    },
    showSidebarAdd () {
      this.addingElement = true;
      this.show();
    },
    showSidebarEdit (emitValues) {
      this.setCurrentFont(emitValues);
      this.addingElement = false;
      this.show();
    },
    setCurrentFont (font) {
      this.font = Object.assign({}, font); //clones Object without reference to fix close sidebar issue
    },
    //#endregion

    //#region API-calls
    loadingFonts () {
      this.loading = true;
      this.getFonts();
    },
    reloadFonts () {
      this.showSidebar = false;
      this.$emit("reloadAuditLogs");
      this.getFonts();
    },
    openConfirmModal (index) {
      this.deleteIndex = index; //font does not have an Id at the moment
      this.$refs.confirmDelete.open();
    },
    removeFont () {
      this.axios.delete(`/VideoPostProcessing/DeleteFontElement?fontElementId=${ this.deleteIndex }`)
        .then(() => {
          this.reloadFonts();
          this.$emit("reloadAuditLogs");
        })
        .finally(() => {
          this.deleteIndex = null;
          this.$refs.confirmDelete.close();
        });
    },
    getFonts () {
      this.axios.get('/VideoPostProcessing/GetAllFontElements')
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          this.fonts = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    testFont (fontId) {
      this.axios.get(`/VideoPostProcessingResource/GetFont/${ fontId }`)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          this.testImageUrl = response.data.fontBase;
          this.$refs.imageViewerModal.open();
        })
        .finally(() => {
          this.loading = false;
        });
    }
    //#endregion
  }
}
</script>
<style>
.fontsList .k-grid table tr:hover td {
  background :rgb(219, 219, 219) !important;
  cursor: pointer !important;
}
</style>