<template>
  <!--
    Used to create a font
  -->
  <div class="fontsAdd">
    <form>
      <div class="form-group">
        <h4 class="m-0 mt-3">
          {{ $t('fontAddComponent.font') }}
        </h4>
        <hr class="m-0 mb-3">
        <label>{{ $t('name') }}</label>
        <input
          v-model="fontElement.name"
          v-focus
          :class="['form-control', { 'is-invalid': $validator.errors.has('Name') }]"
          type="text"
        >
        <hr class="m-0 mb-3">
        <label>{{ $t('description') }}</label>
        <input
          v-model="fontElement.description"
          v-focus
          :class="['form-control', { 'is-invalid': $validator.errors.has('Description') }]"
          type="text"
        >
        <hr class="m-0 mb-3">
        <label>{{ $t('fontAddComponent.fontFile') }}</label>
        <div class="input-group mb-3">
          <input
            ref="fileInput"
            type="file"
            class="form-control cursor-pointer rounded"
            @change="getFileFromInput($event)"
          >
        </div>
        <div
          v-if="uploadPercentage > 0"
          class="input-group mb-3"
        >
          <div class="input-prepend mr-3">
            <label style="width:50px;">{{ $t('staticResourceUploaderComponent.progress') }}</label>
          </div>
          <div class="progress">
            <div
              :aria-valuenow="uploadPercentage"
              :style="`width:${ uploadPercentage }%;`"
              class="progress-bar"
              role="progressbar"
              aria-valuemin="0"
              aria-valuemax="100"
            >
              {{ uploadPercentage }} %
            </div>
          </div>
        </div>
        <span
          v-show="errors.has('Name')" 
          class="badge badge-danger"
        >{{ errors.first('Name') }}</span>
      </div>
      <button 
        class="btn btn-primary float-right" 
        :disabled="!file || !fontElement.name"
        @click.prevent="addAndUploadFont()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="check"
        />{{ $t('add') }}
      </button>
      <div class="clearfix" />
    </form>
  </div>
</template>

<script>
import { errorMixin } from '@/mixins/errorMixin.js';

export default {
  name: "FontsAdd",
  mixins: [
    errorMixin
  ],
  data () {
    return {
      fontElement: {},
      file: null,
      fileName: null,
      uploadPercentage: null,
      uploadingRightNow: false
    }
  },
  methods: {
    async addFont () {
       let postObject = {
            'name': this.fontElement.name,
            'sourceName': this.fileName,
            'description': this.fontElement.description,
      }
      await this.axios.post(`/VideoPostProcessing/AddFontElement`, postObject)
        .then((response) => {
          //this.$emit("reload");
          this.fontElement.id = response.data; //TO BE TESTED
          this.error_clear();
        })
        .catch((error) => {
          this.error_clear();
          this.error_validate(error);
        });
    },
    async uploadFont () {
      this.uploadingRightNow = true;
      this.uploadPercentage = 0;
      let bodyFormData = new FormData();
      bodyFormData.append('formFile', this.file);
      await this.axios.post(`/VideoPostProcessingResource/UploadFont/${this.fontElement.id}`, 
        bodyFormData,
        { headers: { 'Content-Type': 'multipart/form-data' },
          onUploadProgress: function ( progressEvent ) {
            this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total ) );
          }.bind(this)
        }
      )
      .then((response) => {
        if (response && response.status && response.status == 200) {
          this.$emit("reload");
          this.$snotify.success(this.$t('staticResourceUploaderComponent.fileSavedMessage'));
        }
      })
      .finally(() => {
        this.uploadingRightNow = false;
        setTimeout(() => {
          this.uploadPercentage = 0;
        }, 2500);
      });
    },
    async addAndUploadFont (){
      await this.addFont();
      await this.uploadFont();
    },
    getFileFromInput () {
      this.file = this.$refs.fileInput.files[0];
      this.fileName = this.$refs.fileInput.files[0].name;
      this.uploadPercentage = 0;
    }
  }
}
</script>
<style scoped>
.fontsAdd .progress {
  display: flex;
  flex: 1 1 auto;
  width: 1%;
  height: 20px;
  font-size: 1rem;
}
</style>
