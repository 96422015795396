<template>
  <!--
    Used to edit the font of the videoPostprocessingTheme.
  -->
  <div class="fontEdit">
    <template v-if="font">
      <form>
        <div class="form-group">
          <h4 class="m-0 mt-3">
            {{ $t('fontEditComponent.font') }}
          </h4>
          <hr class="m-0 mb-3">
          <label>{{ $t('name') }}</label>
          <input
            v-model="font.name"
            v-focus
            :class="['form-control', { 'is-invalid': $validator.errors.has('Name') }]"
            type="text"
          >
          <hr class="m-0 mb-3">
          <label>{{ $t('description') }}</label>
          <input
            v-model="font.description"
            v-focus
            :class="['form-control', { 'is-invalid': $validator.errors.has('Description') }]"
            type="text"
          >
          <span
            v-show="errors.has('Description')"
            class="badge badge-danger"
          >{{ errors.first('Description') }}</span>
        </div>
        <button
          class="btn btn-sm btn-primary float-right"
          @click.prevent="updateFont()"
        >
          <font-awesome-icon
            class="mr-2"
            icon="check"
          />{{ $t('save') }}
        </button>
        <div class="clearfix" />
      </form>
    </template>
  </div>
</template>

<script>
import { errorMixin } from '@/mixins/errorMixin.js';

export default {
  name: "FontEdit",
  mixins: [
    errorMixin
  ],
  props: {
    font: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      loading: true,
    }
  },
  methods: {
    updateFont () {
      let fontDto = {
        'id': this.font.id,
        'description': this.font.description,
        'sourceName': this.font.sourceName,
        'name': this.font.name
      };
      this.axios.put('/VideoPostProcessing/UpdateFontElement', fontDto)
        .then(() => {
          this.$emit("reload");
          this.error_clear();
          this.$snotify.success(this.$t('fontEditComponent.updatedSuccessfully'));
        })
        .catch((error) => {
          this.error_clear();
          this.error_validate(error);
        });
    }
  }
}
</script>
